<template>
  <div class="card p-0 m-0">
    <div class="card-body p-0 m-0">
      <h4 class="card-title ms-2 mt-2">Orderdetailpagina</h4>
      <div class="row p-0 ms-0 me-2">
        <div class="col-12 p-1 m-0 d-flex justify-content-between">
          <h3>{{ order.customer?.name }}{{ getHeader(order.reference) }}{{ getHeader(order.vo_nummer) }}</h3>
          <button v-if="order.status?.id == 2 || order.status?.id == 4" 
            type="button" @click="resentOrder()" class="btn btn-primary">Verstuur opnieuw</button>
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-lg-3 col-md-4 mt-2 mb-2">
          <div class="d-flex align-items-center">
            <i class="bi bi-circle-fill me-2" :class="getColor(order.status?.id)"></i>
            <select v-model="order.status.id" @change="updateOrderStatus" class="form-select" :disabled="isStatusLocked">
              <option v-for="status in orderstatussen" :key="status.id" :value="status.id">
                {{ status.label }}
              </option>
            </select>
          </div>
          <div v-if="!order.history || order.history.length == 0">{{ order.error_message }}</div>
          <div v-for="history in sortedHistory" :key="history.id" class="mt-2 small-text">
            <b>{{ getFormattedDate(history.created_at) }}</b> - {{ history.error_message }}
            <ul v-if="history.line_errors && history.line_errors.length" class="mt-1 ms-3 mb-0 line-errors">
              <li v-for="(error, index) in history.line_errors" :key="index">
                {{ error.message }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-9 col-md-8 mb-3 py-2">
          <OrderDetailTab @click="select"></OrderDetailTab>
          <div class="border no-top-border p-3 custom-border-radius"> 
            <div class="row" v-if="selectedView == 'object'">
              <div class="orderline-header">
                <div class="card mb-2">
                  <div class="card-body">
                    <h4 class="card-title">Order</h4>
                      <h6 class="card-subtitle mb-2 text-body-secondary">
                        <b>Debiteur: </b> {{ order.output?.debtorCode }} - 
                        <b>Referentie: </b> {{ order.output?.reference }} - 
                        <b>Totaalprijs: </b> €{{ Number(order.output?.netAmountCustomer).toFixed(2) }}
                      </h6>
                      <div class="col-auto">
                        <label for="invoiceAddressCode" class="col-form-label">Factuuradrescode</label>
                        <EditOrderProp
                          id="invoiceAddressCode"
                          :type="'text'"
                          :classStr="'text-begin'"
                          :styleStr="'max-width: 8em;'"
                          :index="1"
                          :orderProperty="{value: invoiceAddressCode}"
                          @changed="invoiceAddressCodeChanged"
                        />
                      </div>
                  </div>
                </div>
              </div>
              <div class="orderlines-list">
                <div v-for="(orderline, index) in orderlines" :key="index" class="orderline-item">
                  <div class="card mb-2">
                    <div class="card-body">
                      <h5 class="card-title">Orderregel {{ orderline.orderLineNumber }}</h5>
                      <h6 class="card-subtitle mb-2 text-body-secondary">
                        <b>Aantal:</b> {{ orderline.qtyOrdered.toFixed(0) }} - 
                        <b>Prijs ex.btw:</b> €{{ Number(orderline.linePrice).toFixed(2) }}
                      </h6>
                      <div class="row custom-row ms-0 me-0">
                        <div class="col-auto">
                          <label for="productCode" class="col-form-label">Product code</label>
                          <EditOrderlineProp
                            id="productCode"
                            :type="'text'"
                            :classStr="'text-begin'"
                            :styleStr="'max-width: 12em;'"
                            :rowIndex="index"
                            :orderlineProperty="{ value: orderline.productIdentifier?.productCode }"
                            @changed="productCodeChanged"
                          />
                        </div>
                        <div class="col-auto">
                          <label for="unitCode" class="col-form-label">Unit code</label>
                          <EditOrderlineProp
                            id="unitCode"
                            :type="'text'"
                            :classStr="'text-begin'"
                            :styleStr="'max-width: 5em;'"
                            :rowIndex="index"
                            :orderlineProperty="{ value: orderline.unitCode }"
                            @changed="unitCodeChanged"
                          />
                        </div>
                        <div class="col-auto">
                          <label for="deliveryAddressCode" class="col-form-label">Delivery adres</label>
                          <EditOrderlineProp
                            id="deliveryAddressCode"
                            :type="'text'"
                            :classStr="'text-begin'"
                            :styleStr="'max-width: 8em;'"
                            :rowIndex="index"
                            :orderlineProperty="{value: orderline.deliveryAddress?.id}"
                            @changed="addressCodeChanged"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <json-tree v-if="selectedView == 'object'" :data="order.output"></json-tree>
            <editable-json-view
              v-if="selectedView == 'json'"
              ref="editableJsonView"
              :editable="order.status?.id != 1 && order.status?.id != 3 && order.status?.id != 6"
              :initialJson="order.output"
              @updateJson="updateJson"
            ></editable-json-view> 
          </div>
        </div>
      </div>
      <!-- Add any modals or additional components if needed -->
      <div class="modal fade" id="confirmResentModal" tabindex="-1" aria-labelledby="confirmResentModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="confirmResentModalLabel">Bevestiging</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Let op, dit maakt aangebrachte wijzigingen in de order ongedaan.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuleren</button>
              <button type="button" class="btn btn-primary" @click="confirmResent">Bevestigen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceFactory from "../services/ServiceFactory";
import Utils from "../utils/Utils";
import EditOrderProp from "../components/EditOrderProp.vue";
import EditOrderlineProp from "../components/EditOrderlineProp.vue";
import JsonTree from "../components/JsonTree.vue";
import OrderDetailTab from "../components/OrderDetailTab.vue";
import EditableJsonView from "../components/EditableJsonView.vue";

export default {
  name: "OrderDetailPage",
  props: ["id"],
  emits: ["showToast"],
  components: {
    EditOrderlineProp,
    EditOrderProp,
    JsonTree,
    OrderDetailTab,
    EditableJsonView,
  },
  data() {
    return {
      orderService: {},
      order: { status: { id: 1 } },
      orderlines: [],
      selectedView: "object",
      orderstatussen: [],
      originalStatusId: null,
      invoiceAddressCode: null,
    };
  },
  computed: {
    isStatusLocked() {
      return this.order.status.id === 1 || this.order.status.id === 3 || this.order.status.id === 6;
    },
    sortedHistory() {
      if (!this.order?.history) {
        return [];
      }
      return [...this.order.history].sort((a, b) => {
        if (!a.created_at) return 1;
        if (!b.created_at) return -1;
        return new Date(b.created_at) - new Date(a.created_at);
      });
    },
  },
  methods: {
    getColor(status) {
      return Utils.getColorForOrderStatus(status);
    },
    getPrice(price) {
      return Utils.formatCurrency(price);
    },
    async loadOrderDetail() {
      this.orderService = ServiceFactory.getService("OrderService");
      //console.log("orderService:", this.orderService.getOrderstatussen());
      this.order = await this.orderService.getOrder(this.id);
      console.log("order:", this.order);
      this.orderlines = this.order.output?.lines || [];
      this.orderstatussen = await this.orderService.getOrderstatussen();
      this.originalStatusId = this.order.status.id;
      this.invoiceAddressCode = this.order.output?.invoiceAddressCode;
      console.log("invoiceAddressCode:", this.invoiceAddressCode);
    },
    getHeader(txt) {
      return txt && txt !== "" ? " - " + txt : "";
    },
    getFormattedDate(date) {
      const formattedDate = Utils.formatDateTimeString(date);
      if (!formattedDate) {
        return "#:#";
      }
      return formattedDate;
    },
    addressCodeChanged(value, index) {
      console.log(`addressCodeChanged: (${value}, ${index})`);
      this.orderlines[index].deliveryAddress.id = value;
      this.order.output.lines[index].deliveryAddress.id = value;
      // Update the order on the server if needed
    },
    unitCodeChanged(newUnitCode, index) {
      this.orderlines[index].unitCode = newUnitCode;
      this.order.output.lines[index].unitCode = newUnitCode;
    },
    productCodeChanged(value, index) {
      console.log(`productCodeChanged: (${value}, ${index})`);
      this.orderlines[index].productIdentifier.productCode = value;
      this.order.output.lines[index].productIdentifier.productCode = value;
      // Update the order on the server if needed
    },
    select(view) {
      this.selectedView = view;
    },
    async updateJson(newJson) {
      this.jsonChanged = true;
      this.order.output = newJson;
    },
    showToast(message) {
      this.$emit("showToast", message);
    },
    async updateOrderStatus() {
      if (this.isStatusLocked) {
        this.$emit("showToast", ["Deze orderstatus kan niet worden gewijzigd", "bg-warning"]);
        this.order.status.id = this.originalStatusId;
        return;
      }
      try {
        const result = await this.orderService.updateOrderStatus(this.order.id, this.order.status.id);
        if (result.success) {
          this.$emit("showToast", ["Orderstatus succesvol bijgewerkt", "bg-success"]);
          this.originalStatusId = this.order.status.id;
        } else {
          this.$emit("showToast", [`Fout bij het bijwerken van de orderstatus: ${result.message}`, "bg-danger"]);
          this.order.status.id = this.originalStatusId;
        }
      } catch (error) {
        console.error("Fout bij het updaten van de orderstatus:", error);
        this.$emit("showToast", ["Er is een fout opgetreden bij het bijwerken van de orderstatus", "bg-danger"]);
        this.order.status.id = this.originalStatusId;
      }
    },
    async resentOrder() {
      if (this.selectedView == 'json') {
        const editableJsonView = this.$refs.editableJsonView;
        if (editableJsonView) {
          const jsonContent = editableJsonView.jsonContent;
          this.order.output = jsonContent;
          const result = await this.orderService.retryOrder(this.order.id, jsonContent);
          console.log('result:', result);
          if(result.success) {
            this.$router.push('/orders');
          } else {
            console.log('result:', result);
            this.$emit('showToast', [`Er is een fout opgetreden bij het opnieuw versturen van de order: ${result.message.error}`, 'bg-danger']);
          }
        }
        return;
      }
      if (this.jsonChanged) {
        this.modal = new window.bootstrap.Modal(document.getElementById('confirmResentModal'));
        this.modal.show();
      } else {
        await this.performResent();
      }
    },
    async confirmResent() {
      this.modal.hide();
      await this.performResent();
    },
    async performResent() {
      const result = await this.orderService.retryOrder(this.order);
      if (result.success) {
        this.modal.hide();
      } else {
        this.$emit('showToast', [`Er is een fout opgetreden bij het opnieuw versturen van de order: ${result.message.error}`, 'bg-danger']);
      }
      this.loadOrderDetail();
      console.log('result:', result);
    },
    invoiceAddressCodeChanged(invoiceAddressCode, index) {
      console.log(`Invoice Address Code gewijzigd: ${invoiceAddressCode} op index ${index}`);
      this.order.output.invoiceAddressCode = invoiceAddressCode;
    },
  },
  mounted() {
    this.loadOrderDetail();
  },
};
</script>

<style scoped>
.custom-border-radius {
  border-radius: 0 0 7px 7px;
}
.no-top-border {
  border-top: none !important;
}
.orderlines-list {
  display: flex;
  flex-direction: column;
}
.orderlines-header {
  display: flex;
  flex-direction: column;
}
.orderline-item {
  padding: 0.5em 0;
}
.custom-row {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1em;
  margin-bottom: 1em;
}
.orderline-item .row {
  margin-bottom: 0.5em;
}
@media (max-width: 767px) {
  .orderline-item .row > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/* Style for disabled select options if needed */
select option:disabled {
  color: #999;
  font-style: italic;
}
.small-text {
  font-size: 0.875rem; /* Blijft hetzelfde als eerder */
  line-height: 1.2;
}

.small-text ul {
  padding-left: 1rem;
}

.small-text .line-errors {
  font-size: 0.625rem; /* Nog kleiner dan de hoofdtekst */
}

.small-text .line-errors li {
  margin-bottom: 0.2rem;
}
</style>
